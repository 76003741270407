

$( document ).ready(function() {


 
    //slider Apartamenty
    // $('.loop').owlCarousel({
    //   center: true,
    //   items:1,
    //       lazyLoad:true,
		//   autoplayHoverPause:true,
    //       loop:true,
		//   mouseDrag : false,
    //       margin:10,
    //       autoplay:true,
    //       autoplayTimeout:4000,
		//   smartSpeed:1250,
    //       nav:false,
    //       dots:false,
			 
	  //   responsive:{
	  //       0:{
	  //           items:1
	  //       },
	  //       600:{
	  //           items:1
	  //       },
	  //       1000:{
	  //           items:2
	  //       }
	  //   }
    // });

    $('.loop').owlCarousel({
      center: true,
      items:1,
      loop:true,
      margin:10,
      nav:true,
      dots:false,
      responsive:{
          600:{
              items:2
          }
      }
  });
  
    //end slider Apartamenty


    //slider mainPageSlider
    $('.mainPageSlider').owlCarousel({
        items:1,
          lazyLoad:true,
		  autoplayHoverPause:true,
          loop:true,
		  mouseDrag : false,
          margin:0,
          autoplay:true,
          autoplayTimeout:4000,
		  smartSpeed:1250,
          nav:false,
          dots:false,
          navText: [$('.owl-left'),$('.owl-right')]
    });
    //end slider mainPageSlider

    //slider photoSlider
    $('.photoSlider').owlCarousel({
        items:1,
        lazyLoad:true,
        loop:true,
        margin:10,
        autoplay:true,
        autoplayTimeout:4000,
        smartSpeed:1250,
        nav:false,
        dots:true,
        //navText: [$('.owl-left'),$('.owl-right')]
  });
  //end slider photoSlider

    
      //animacja wejscia dla stron
      //document.body.classList.add('fadeIn');

    //header scroll fixed
     $(window).scroll(function(){ 
      var scroll_start = 0;
      var startchange = $('#navbarNavDropdown');
      var offset = startchange.offset();
      var offsetBL = (startchange.offset().top)-200;
      //console.log(offsetBL); //697
      //console.log(offset.top); /847
      
      if (startchange.length){
        $(document).scroll(function() { 
           scroll_start = ($(this).scrollTop()); //
           if(scroll_start > offset.top) {
              //strona główna
              $('#navbarNavDropdown').addClass('header__fixed');
            } else if ($(window).scrollTop() < 450) {
              $('#navbarNavDropdown').removeClass('header__fixed');
              $('.header__Logo__main').removeClass('hidden');
              $('.header__Logo__fixed').removeClass('show');
            };
            if(scroll_start > (offsetBL)) {
              //console.log(offsetBL);
              //console.log(scroll_start);
              $('.header__Logo__main').addClass('hidden');
              $('.header__Logo__fixed').addClass('show');
            } 
        });
      }
      if ($(window).scrollTop() >= 768 ) {
        $('#menu-item-81').removeClass('header__fixed__hidden');
        $('#menu-item-80').removeClass('header__fixed__hidden');
        $('#menu-item-79').removeClass('header__fixed__hidden');
        $('.header__socialMedia__main').addClass('hidden');
        $('.header__socialMedia__fixed').addClass('show');
        }
        else {
          $('#menu-item-81').addClass('header__fixed__hidden');
          $('#menu-item-80').addClass('header__fixed__hidden');
          $('#menu-item-79').addClass('header__fixed__hidden');
          $('.header__socialMedia__main').removeClass('hidden');
          $('.header__socialMedia__fixed').removeClass('show');
          
          $('#navbarNavDropdown').removeClass('header__fixed');
          $('.header__Logo__main').removeClass('hidden');
          $('.header__Logo__fixed').removeClass('show');
          //podstrona
          //$('#mainSectionLogo').removeClass('mainSection__fixed');
      }
      
    }); 
  

  /* tła dla 3-ch bloków na głównej stronie */
  jQuery('#SLiderTop1').mouseenter(function(e) {
        jQuery('#slide1').animate({
                "background-size":"400%",
                "background-color":"rgba(0,0,0,0.8);"
              }, 600	);       
        jQuery('#slide1Title').animate({
                "font-size":"4.5rem"
              }, 600	);
	    }).mouseleave(function(e) {
        jQuery('#slide1').animate({
	        	"background-size":"280%"
          }, 600	);
        jQuery('#slide1Title').animate({
            "font-size":"4rem"
          }, 600	);
  });
  jQuery('#SLiderTop2').mouseenter(function(e) {
        jQuery('#slide2').animate({
                "background-size":"400%"
              }, 600	);     
        jQuery('#slide2Title').animate({
                "font-size":"4.5rem"
              }, 600	);  
	    }).mouseleave(function(e) {         
        jQuery('#slide2').animate({
	        	"background-size":"280%"
          }, 600	);        
        jQuery('#slide2Title').animate({
            "font-size":"4rem"
          }, 600	);
  });
  jQuery('#SLiderTop3').mouseenter(function(e) {
        jQuery('#slide3').animate({
                "background-size":"400%"
              }, 600	);  
          jQuery('#slide3Title').animate({
                  "font-size":"4.5rem"
                }, 600	);       
	    }).mouseleave(function(e) {     
        jQuery('#slide3').animate({
	        	"background-size":"280%"
	        }, 600	);
          jQuery('#slide3Title').animate({
              "font-size":"4rem"
            }, 600	);
  });
  /* END tła dla 3-ch bloków na głównej stronie */

  
  
    jQuery("#SLiderTop1").click(function(e){
    location.href = '/zajezdnia/restauracja/';
  });
  
  jQuery("#SLiderTop2").click(function(e){
    location.href = '/zajezdnia/minibrowar/';
  });
  
  jQuery("#SLiderTop3").click(function(e){
    location.href = '/zajezdnia/eventy/';
  });

  
  // animacja: ruchome liczby w formie bębna
    function animationCounter(){
      $('.CountRP').each(function () {
          $(this).prop('Counter',0).animate({
              Counter: $(this).text()
          }, {
              duration: 3000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
      });
  }

  var counterTeaserL = $('.go-counterTeaser');
  var winHeight = $(window).height();
  if (counterTeaserL.length) {
      var firEvent = false,
          objectPosTop = $('.go-counterTeaser').offset().top;          
          //when element shows at bottom
          var elementViewInBottom = objectPosTop - winHeight;
      $(window).on('scroll', function() {
          var currentPosition = $(document).scrollTop();
          //when element position starting in viewport
      if (currentPosition > elementViewInBottom && firEvent === false) {
          firEvent = true;
          animationCounter();
      }   
      });
  }

  // włączenie filmów
    $(".FilmFromYotube0 .film__content__player").click(function(){
      $(".FilmFromYotube0 .film__content").addClass('novisible');
      $(".FilmFromYotube0 .film__film").addClass('showvisible');
      var videoURL = $('#video0').prop('src');
      videoURL += "&autoplay=1";
      $('#video0').prop('src',videoURL);
  });
    $(".FilmFromYotube1 .film__content__player").click(function(){
      $(".FilmFromYotube1 .film__content").addClass('novisible');
      $(".FilmFromYotube1 .film__film").addClass('showvisible');
      var videoURL = $('#video1').prop('src');
      videoURL += "&autoplay=1";
      $('#video1').prop('src',videoURL);
  });
  $(".FilmFromYotube2 .film__content__player").click(function(){
    $(".FilmFromYotube2 .film__content").addClass('novisible');
    $(".FilmFromYotube2 .film__film").addClass('showvisible');
    var videoURL = $('#video2').prop('src');
    videoURL += "&autoplay=1";
    $('#video2').prop('src',videoURL);
  });
  $(".FilmFromYotube3 .film__content__player").click(function(){
    $(".FilmFromYotube3 .film__content").addClass('novisible');
    $(".FilmFromYotube3 .film__film").addClass('showvisible');
    var videoURL = $('#video3').prop('src');
    videoURL += "&autoplay=1";
    $('#video3').prop('src',videoURL);
  });
  $(".FilmFromYotube4 .film__content__player").click(function(){
    $(".FilmFromYotube4 .film__content").addClass('novisible');
    $(".FilmFromYotube4 .film__film").addClass('showvisible');
    var videoURL = $('#video4').prop('src');
    videoURL += "&autoplay=1";
    $('#video4').prop('src',videoURL);
  });
  $(".FilmFromYotube5 .film__content__player").click(function(){
    $(".FilmFromYotube5 .film__content").addClass('novisible');
    $(".FilmFromYotube5 .film__film").addClass('showvisible');
    var videoURL = $('#video5').prop('src');
    videoURL += "&autoplay=1";
    $('#video5').prop('src',videoURL);
  });
  
    //zanikanie toppera po scrolu w dół

    const checkpoint = 600;
    var opacity = 1;
    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= checkpoint) {
            opacity = 1 - currentScroll / checkpoint;
        } else {
            opacity = 0;
        }
        jQuery("#thePlaceOpacity").css("opacity", opacity);
        //$(".header__backgroundPhoto").css("background", opacity);
    }); 

    // zanikanie logo    
    /* 
    var opacityLogo = 1;
    window.addEventListener("scroll", () => {
        const currentScrollLogo = window.pageYOffset;
        const checkpointLogo = currentScrollLogo - 100;
        if (currentScrollLogo > checkpointLogo) {
          opacityLogo = 1 - currentScrollLogo / checkpointLogo;
        } else {
          opacityLogo = 0;
        }
        jQuery(".mainSection__fixed").css("opacity", opacityLogo);
        //$(".header__backgroundPhoto").css("background", opacity);
    });  */
}); 
